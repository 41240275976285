<template>
  <div>
    <div>
      <span>{{ clinRec.name }}</span>
    </div>
    <div>
      <span class="mr-2">ID:</span>
      <span class="mr-6 font-weight-bold">{{ clinRec.external_id }}</span>

      <span class="mr-2">МКБ10:</span>
      <span class="mr-6 font-weight-bold">{{ clinRec.mkb10_codes }}</span>

      <span class="mr-2">Возрастная группа:</span>
      <span class="mr-6 font-weight-bold">{{ clinRec.age_category }}</span>

      <span class="mr-2">Профиль:</span>
      <span v-if="clinRec.profile" class="mr-6 font-weight-bold">{{
        clinRec.profile
      }}</span>
      <span v-else class="mr-6 red--text">Не установлен</span>

      <span class="mr-2">Аналитик ЦЭККМП:</span>
      <span class="font-weight-bold">{{ clinRec.analyst }}</span>
    </div>

    <!-- Крайний срок -->
    <div v-if="showDeadlineAlert" class="mt-2">
      <v-alert dense :color="dayDifference.color" class="white--text">
        <span class="mr-2">Крайний срок:</span>
        <span class="mr-2 font-weight-bold">{{ deadline }}</span>
        <span v-if="dayDifference.shortText"
          >({{ dayDifference.shortText }})</span
        >
      </v-alert>
    </div>
    <div v-else class="mt-2">
      <span class="mr-2">Крайний срок:</span>
      <span class="mr-2 font-weight-bold">{{ deadline }}</span>
      <span v-if="showDeadlineWithDays"
        >({{ dayDifference.textWithDays }})</span
      >
    </div>

    <!-- Комментарий -->
    <div v-if="clinRec.comment" class="d-flex mt-2">
      <div class="mr-2">Комментарий:</div>
      <div>{{ clinRec.comment }}</div>
    </div>

    <!-- Файлы -->
    <div v-if="clinRec.files" class="mt-2">
      <div v-if="clinRec.files.length">
        <div class="font-weight-bold">Файлы:</div>
        <FileList :files="clinRec.files" :mb="2" />
      </div>
    </div>
  </div>
</template>

<script>
import FileList from '@/components/cards/FileList.vue'
import { displayDate, getDisplayDayDifference } from '@/components/helpers'

export default {
  props: {
    clinRec: {
      type: Object,
      required: true,
    },
    showDeadlineAlert: {
      type: Boolean,
      default: false,
    },
    showDeadlineWithDays: {
      type: Boolean,
      default: true,
    },
  },
  components: { FileList },
  computed: {
    deadline() {
      return this.clinRec.time_deadline
        ? displayDate(this.clinRec.time_deadline)
        : false
    },
    dayDifference() {
      return this.clinRec.time_deadline
        ? getDisplayDayDifference(new Date(), this.clinRec.time_deadline)
        : {}
    },
  },
}
</script>
