<template>
  <div>
    <div v-if="files.length">
      <span>Список файлов</span>
      <ul>
        <li class="file" v-for="file in filesWithKey" :key="file.key">
          <span
            v-if="file.id"
            @click="onFileClick(file)"
            class="filename--downloadable"
            title="Скачать"
            >{{ file.name }}</span
          >
          <span v-else>{{ file.name }}</span>
          <span
            class="delete-file red--text text-h5 ml-2"
            @click="onFileDelete(file)"
            title="Удалить"
            >&times;</span
          >
        </li>
      </ul>
    </div>
    <input @change="handleFileLoad" ref="file" type="file" class="d-none" />
    <v-btn @click="onFileButtonClick" text class="pl-0 pr-2"
      ><v-icon class="mr-2"> mdi-paperclip </v-icon> {{ fileButtonText }}
    </v-btn>
  </div>
</template>

<script>
import { downloadFile } from '@/components/helpers'
import { nanoid } from 'nanoid'
import { fileActions, addFile, deleteFile } from '@/store/const/fileActions'

export default {
  props: ['files', 'stagedFiles', 'edit'],
  computed: {
    // свойство как у v-text-field, чтобы можно было обрабатывать в одном цикле.
    valid() {
      return true
    },
    fileButtonText() {
      return this.files.length ? 'Добавить еще файл' : 'Добавить файл'
    },
    filesWithKey() {
      const filesWithKey = this.files.slice()
      filesWithKey.forEach(file => {
        file.key = file.id ? file.id : nanoid()
      })
      return filesWithKey
    },
  },
  methods: {
    // validate и reset — методы v-text-field, чтобы можно было обрабатывать в одном цикле.
    validate() {
      return true
    },
    reset() {
      this.$refs.file.value = null
    },
    onFileButtonClick() {
      this.$refs.file.click()
    },
    onFileDelete(file) {
      if (this.edit) {
        this.updateStagedOnDelete(file)
      }
      this.deleteFromVisualList(file)
    },
    onFileClick(file) {
      downloadFile(file.id, file.original_name)
    },
    updateStagedOnDelete(file) {
      const { key } = file
      const fileIndexInStaged = this.stagedFiles.findIndex(
        f => f.file.key === key
      )
      const newStagedFiles = this.stagedFiles.slice()
      if (fileIndexInStaged !== -1) {
        newStagedFiles.splice(fileIndexInStaged, 1)
      } else {
        newStagedFiles.push({ fileAction: fileActions[deleteFile], file })
      }
      this.$emit('staged-files', newStagedFiles)
    },
    deleteFromVisualList(file) {
      const { key } = file
      const fileIndex = this.files.findIndex(f => f.key === key)
      const newFiles = this.files.slice()
      newFiles.splice(fileIndex, 1)
      this.$emit('files', newFiles)
    },
    handleFileLoad(evt) {
      /*   
        Поскольку использую стандартный input, до файла приходится добираться через event.
        Можно переделать на v-file-input, тогда в ответ на change будет приходить файл,
        но в v-file-input, чтобы добраться до собственно инпута и имитировать клик на нем
        нужно два раза использовать $refs.
        Пока решили что так как сделано меньшее из зол, т.к. меньше зависим от внутренностей vuetify
      */
      const file = evt.target.files[0]
      if (!file || !file.name) return

      // Возможно получится порефакторить работу c newFiles и newStagedFiles во всем компоненте
      const newFiles = this.files.slice()
      const newStagedFiles = this.stagedFiles.slice()
      newFiles.push(file)
      this.$emit('files', newFiles)
      newStagedFiles.push({ fileAction: fileActions[addFile], file })
      this.$emit('staged-files', newStagedFiles)
    },
  },
}
</script>

<style scoped>
.filename--downloadable {
  border-bottom: 1px dashed #1976d2;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.15s ease;
}
.filename--downloadable:hover {
  opacity: 0.8;
}
.delete-file {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.15s ease;
}
.file:hover .delete-file {
  opacity: 1;
}
</style>
