<template>
  <ul>
    <li v-for="file in files" :key="file.id" :class="liClass">
      <span
        class="filename"
        title="Скачать"
        @click="onFileClick(file.id, file.original_name)"
        >{{ file.original_name }}</span
      >
    </li>
  </ul>
</template>

<script>
import { downloadFile } from '@/components/helpers'

export default {
  props: {
    files: {
      type: Array,
      required: true,
    },
    mb: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    liClass() {
      return this.mb ? `mb-${this.mb}` : ''
    },
  },
  methods: {
    onFileClick(fileId, fileName) {
      downloadFile(fileId, fileName)
    },
  },
}
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
}
.filename {
  border-bottom: 1px dashed #1976d2;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.15s ease;
}
.filename:hover {
  opacity: 0.8;
}
</style>
