import { ONCOLOGY, CARDIOVASCULAR_DISEASES } from '@/store/const/profiles'

export const questionsList = {
  [ONCOLOGY]: [
    {
      id: 0,
      title: 'Перечень тезисов-рекомендаций',
    },
    {
      id: 1,
      title:
        'Сопоставление тезисов-рекомендаций и Номенклатуры медицинских услуг',
    },
    {
      id: 2,
      title:
        'Сопоставление тезисов-рекомендаций и методов высокотехнологичной медицинской помощи',
    },
    {
      id: 3,
      title:
        'Перечень элементов схем лекарственной терапии, которые применяются в сопоставлении с тезисами-рекомендациями',
    },
    {
      id: 4,
      title:
        'Перечень схем лекарственной терапии, которые применяются в сопоставлении с тезисами-рекомендациями',
    },
    {
      id: 5,
      title:
        'Сопоставление схем лекарственной терапии и элементов схем лекарственной терапии (перечень состава схем)',
    },
    {
      id: 6,
      title: 'Сопоставление тезисов-рекомендаций и схем лекарственной терапии',
    },
    {
      id: 7,
      title:
        'Сопоставление тезисов-рекомендаций и схем лекарственной терапии в соответствии с перечнем ФОМС',
    },
    {
      id: 8,
      title:
        'Сопоставление тезисов-рекомендаций и сведений об оказании лучевой терапии',
    },
    {
      id: 9,
      title:
        'Перечень маршрутов (алгоритмов ведения пациента) в соответствии с клинической рекомендацией',
    },
    {
      id: 10,
      title:
        'Сопоставление подэтапов оказания медицинской помощи и тезисов-рекомендаций по каждому из маршрутов (алгоритмов ведения пациента)',
    },
    {
      id: 11,
      title:
        'Графическое представление маршрутов (алгоритмов ведения пациента) в соответствии с клинической рекомендацией',
    },
  ],
  [CARDIOVASCULAR_DISEASES]: [
    {
      id: 0,
      title: 'Ознакомление с перечнем тезисов-рекомендаций (лист Тезисы(КР))',
    },
    {
      id: 1,
      title:
        'Согласование сопоставления тезисов-рекомендаций и Номенклатуры медицинских услуг (Приказ 804н) (лист Тезисы-Услуги)',
    },
    {
      id: 2,
      title:
        'Согласование сопоставления тезисов-рекомендаций и методов высокотехнологичной медицинской помощи (актуальный справочник предоставляется по запросу) (лист Тезисы-Услуги)',
    },
    {
      id: 3,
      title:
        'Согласование сопоставления тезисов-рекомендаций и элементов лекарственной терапии (проверка полноты отражения информации из текста КР) (лист Тезисы-Схемы)',
    },
    {
      id: 4,
      title:
        'Анализ, экспертиза и дополнение информации, отсутствующей в тексте КР, по перечню элементов лекарственной терапии (лист ЛП)',
    },
    {
      id: 5,
      title:
        'Перечень маршрутов (алгоритмов ведения пациента) в соответствии с текстом клинической рекомендации (лист Маршруты)',
    },
    {
      id: 6,
      title:
        'Проверка наполнения подэтапов оказания медицинской помощи тезисами по каждому из маршрутов (алгоритмов ведения пациента) (лист Подэтапы)',
    },
    {
      id: 7,
      title:
        'Ознакомление с графическим представлением маршрутов (алгоритмов ведения пациента) в соответствии с текстом клинической рекомендации (файлы в формате PDF или bpmn по запросу)',
    },
  ],
}
