export const ANSWERS = [
  { id: 1, text: 'Да' },
  {
    id: 2,
    text: 'Нет',
  },
  { id: 3, text: 'Не профильный вопрос' },
]

// временная затычка чтобы работало и так и так
export const answers = [
  { id: 1, text: 'Да' },
  {
    id: 2,
    text: 'Нет',
  },
  { id: 3, text: 'Не профильный вопрос' },
]

export const YES_ANSWER_ID = 1
export const NO_ANSWER_ID = 2
export const NOT_MY_ANSWER_ID = 3
